import React, { useState, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(0, 0, 0, .1);
	overflow-y: auto;
	z-index: 100000000;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ModalContainer = styled.div`
	background: #fff;
	padding: 1.5rem;
	border-radius: .5rem;
	min-width: 90%;
	margin: 0 1rem;
	position: relative;
`

export const ModalTitle = styled.div`
	font-size: 1.5rem;
	margin-bottom: 1rem;
`

export const ModalSubTitle = styled.div`
	font-size: 1.2rem;
	margin-top: -.5rem;
	margin-bottom: 1rem;
	color: #aaa;
`

export const ModalContent = styled.div`
	margin-bottom: 1.5rem;
`

export const ModalFooter = styled.div`
	display: flex;
	justify-content: center;
`

export const ModalClose = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	width: 1.5rem;
	line-height: 1.5rem;
	border-radius: 100%;
	text-align: center;
	cursor: pointer;
`

const modalRoot = document.getElementById('modal-root')

export default function Modal ({ as = undefined, onDismiss, children }: { onDismiss?: () => void }) {
	const [modal] = useState(() => window.document.createElement('div'))

	useEffect(() => {
		modalRoot.appendChild(modal)
		window.document.body.style.overflow = 'hidden'

		return () => {
			modalRoot.removeChild(modal)
			window.document.body.style.overflow = ''
		}
	}, [modal])

	const close = useCallback(() => onDismiss.call(), [onDismiss])

	const onClickHandler = useCallback(e => {
		if (e.target === e.currentTarget) {
			onDismiss.call()
		}
	}, [onDismiss])

	return createPortal(
		<ModalOverlay as={as} onClick={onClickHandler}>
			<ModalContainer>
				<ModalClose onClick={close}>&times;</ModalClose>
				{children}
			</ModalContainer>
		</ModalOverlay>,
		modal
	)
}