export const FETCH_PRODUCT_DATA = 'FETCH_PRODUCT_DATA'
export const SET_PRODUCT_DATA = 'SET_PRODUCT_DATA'

const defaultState = {}

const products = (state = defaultState, action) => {
	switch (action.type) {
		case FETCH_PRODUCT_DATA:
			return {
				...state,
				[action.id]: {
					loading: true
				}
			}

		case SET_PRODUCT_DATA: {
			return {
				...state,
				[action.id]: action.data
			}
		}

		default:
			return state
	}
}

export default products