import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router'

import store from './redux/store'
import AppContainer from './components/App'
import FakeJQuery from './fake-jquery'
import * as serviceWorker from './serviceWorker'
// import 'react-input-range/lib/css/index.css'

import './index.css'
import { LOCATION_CHANGE } from './redux/reducers/location'

const history = createBrowserHistory()

// Посылаем события в гугл
history.listen((location) => {
	store.dispatch({
		type: LOCATION_CHANGE
	})

	try {
		window.ga('set', 'page', location.pathname)
		window.ga('send', 'pageview')
	} catch (e) {
	}

	try {
		window.ym(5455108, 'hit', location.pathname)
	} catch (e) {
	}
})

const RenderApp = () => (
	<Provider store={store}>
		<Router history={history}>
			<AppContainer/>
		</Router>
	</Provider>
)

window.$ = window.jQuery = function (selector) {
	return new FakeJQuery(selector)
}

window.$.each = () => {}
window.$.fn = FakeJQuery.prototype
window.$.noConflict = () => {
	return false;
}

ReactDOM.render(<RenderApp/>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()