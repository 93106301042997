import React from 'react'
import { Helmet } from 'react-helmet'

const NoMatch = () => (
	<>
		<Helmet>
			<title>Страница не найдена</title>
			<meta name="robots" content="follow, noarchive, noindex"/>
		</Helmet>

		Страница не найдена
	</>
);

export default NoMatch