import { applyMiddleware, compose, createStore } from 'redux'
import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk'

import { saveState, loadState } from './localStorage'
import rootReducer from './reducers'

const loggerMiddleware = createLogger()

const middlewares = [thunkMiddleware, loggerMiddleware]
const middlewareEnhancer = applyMiddleware(...middlewares)

const enhancers = [middlewareEnhancer]
const composedEnhancers = compose(...enhancers)

const persistedState = loadState()
const store = createStore(rootReducer, persistedState, composedEnhancers)

store.subscribe(() => {
  const { cart: { errors, ...cartState }, order: { token } } = store.getState()

  saveState({
    order: { token },
    cart: cartState
  })
})

export default store