import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'

import AccordionPromo from '../components/AccordionPromo'
import PageDataLoader from '../components/PageDataLoader'
import Page from '../components/Page'
import Product from '../components/Product'
import type { ProductInterface } from '../types'
import Notification from '../components/Notification'
import Slider from 'react-slick/lib'
import { SliderInner } from './ProductPage'
import { Button } from '../components/shared'

// region TopCategories

const TopCategory = styled(Link)``

TopCategory.Placeholder = styled.div``

TopCategory.Title = styled.div`
	color: red;
	padding-top: .5rem;
`

TopCategory.ImageWrapper = styled.div`
	overflow: hidden;
	width: 40vw;
	max-width: 157px;
	height: 174px;
	border-radius: 4px;
	border: 1px solid #B7B6B6;
	box-shadow: 0 3px 3px rgba(0,0,0,.1);
	background: #FFF;
	display: flex;
	align-items: center;
	justify-content: center;
	
	img {
	  max-width: 90%;
	}
`

TopCategory.Image = (props) => (
	<LazyLoad offset={200}>
		<picture>
			<source srcSet={props.src.replace('.jpg', '.webp')} type="image/webp"/>
			<img alt='' {...props}/>
		</picture>
	</LazyLoad>
)

const TopCategoriesContainer = styled.div`
	display: flex;
	margin: 0 -1rem;
	padding: 1rem;
	overflow-x: scroll;
	-webkit-overflow-scrolling: touch;
	
	&::-webkit-scrollbar { 
    display: none; 
	}
	
	&:after {
		content: '';
		padding-left: 1rem;
		display: block;
	}
	
	${TopCategory} + ${TopCategory} {
		margin-left: 1rem;
	}
`

// endregion

const MainSamovar = styled(props => (
	<LazyLoad offset={200}>
		<img alt='' {...props} />
	</LazyLoad>
))`
	margin: 2rem auto 2rem;
	display: block;
`

const CategoryTitle = styled.div`
	font-weight: bold;
	font-size: 1.2rem;
	margin: 1rem 0 .5rem;
`

const TopCategories = ({ categories, scrollPosition }) => {
	return (
		<>
			<Button as={Link} style={{ textAlign: 'center', color: 'white', backgroundColor: 'rgb(255, 79, 79)' }} to='/catalog/'>Перейти в каталог</Button>

			<TopCategoriesContainer>
				{categories.map(category => (
					<TopCategory key={category.id} to={`/category/${category.id}/`}>
						<TopCategory.ImageWrapper>
							<TopCategory.Image title={category.name} src={`https://samovary.ru/images/categories/${category.id}.png`}/>
						</TopCategory.ImageWrapper>
						<TopCategory.Title>{category.name.replace(/\(.+?\)/, '')}</TopCategory.Title>
					</TopCategory>
				))}
			</TopCategoriesContainer>
		</>
	)
}

const HomePage = ({ scrollPosition }) => (
	<>
		<AccordionPromo/>
		<Page>
			<MainSamovar src="https://samovary.ru/images/TS.gif" width={198} height={220} alt="Тульский самовар" title="Тульский самовар" className="initial loading" data-was-processed="true"/>

			<PageDataLoader pageId='home'>
				{(pageData => {
					const parts = []
					const { top_categories, goods, categories, meta: { text2, header } } = pageData
					const text2chunks = text2.split('$categories')

					parts.push({ component: 'h1', text: header })
					parts.push({ component: 'div', text: text2chunks[0] })
					top_categories && parts.push({ component: <TopCategories scrollPosition={scrollPosition} categories={top_categories}/> })
					parts.push({ component: 'div', text: text2chunks[1] })

					categories.forEach(category => {
						let { name } = category

						name = name.replace(/<a .+?<\/a>/g, '')
						name = name.replace(/<.+?>/g, '')

						parts.push({
							component: (
								<>
									<CategoryTitle>{name}</CategoryTitle>
									<Slider {...{
										dots: false,
										infinite: true,
										speed: 500,
										slidesToShow: 1,
										slidesToScroll: 1,
										afterChange: function () {
											window.dispatchEvent(new Event('resize'))
										}
									}}>
										{goods[category.id].map((good: ProductInterface) => {
											return (
												<SliderInner key={good.id}>
													<Product to={`/good/${good.id}/`} good={good}/>
												</SliderInner>
											)
										})}
									</Slider>
								</>
							)
						})
					})

					return (
						<>
							<Helmet>
								<title>{pageData.meta.title}</title>
							</Helmet>

							{parts.map((part, index) => {
								// Тестовый компонент
								if (typeof part.component === 'string') {
									return <part.component key={index} dangerouslySetInnerHTML={{ __html: part.text }}/>
								}

								return React.cloneElement(part.component, {
									key: index
								})
							})}
						</>
					)
				})}
			</PageDataLoader>
		</Page>
		<Notification/>
	</>
)

export default connect()(HomePage)
