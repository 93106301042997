const initialState = [
  {
    icon: 'cash',
    name: 'Оплата при получении',
    id: 2,
  },
  {
    icon: 'card',
    name: 'Онлайн оплата',
    id: 1,
  },
  {
    icon: 'sberbank',
    name: 'Банковский перевод',
    id: 3,
  },
  {
    icon: 'paypal',
    name: 'PayPal',
    id: 4,
  }
]

export default (state = initialState, action) => {
  return state
}