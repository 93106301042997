const defaultState = {
	pageData: {}
}

const pages = (state = defaultState, action) => {
	switch (action.type) {
		case 'FETCH_PAGE_DATA':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageId]: {
						loading: true
					}
				}
			}

		case 'SET_PAGE_DATA':
			return {
				...state,
				pageData: {
					...state.pageData,
					[action.pageId]: {
						loading: false,
						...action.data
					},
				}
			}

		default:
			return state
	}
}

export default pages