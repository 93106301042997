import { connect } from 'react-redux'
import React from 'react'

import { fetchPageData } from '../redux/actions'
import Loading from './Loading'

const PageDataLoader = ({ children, dispatch, pageData, pageId, ...props }) => {
	const { [pageId]: pageState } = pageData

	if (!pageState) {
		dispatch((fetchPageData(pageId, props)))
	}

	if (!pageState || pageState.loading) {
		return <Loading/>
	}

	return children(pageState)
}

export default connect(state => ({ pageData: state.pages.pageData }))(PageDataLoader)