import React, { useEffect, lazy, Suspense } from 'react'
import { Helmet } from 'react-helmet'
import { Route, Switch } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { fetchSidebarCategories } from '../redux/actions'
import ProductPage from '../pages/ProductPage'
import HomePage from '../pages/HomePage'
import Header from '../containers/Header'
import Sidebar from '../containers/Sidebar'
import NoMatch from './NoMatch'
import Loading from './Loading'
import Wrapper from './Wrapper'
import Container from './Container'
import Footer from './Footer'
import Page from './Page'
import { injectScript } from '../utils'
import QuickOrder from './QuickOrder'
import Notification from './Notification'

const ArticlePage = lazy(() => import('../pages/ArticlePage'))
const CategoryPage = lazy(() => import('../pages/CategoryPage'))
const CartPage = lazy(() => import('../pages/CartPage'))
const OrderPage = lazy(() => import('../pages/OrderPage'))
const SearchPage = lazy(() => import('../pages/SearchPage'))
const NewsEntryPage = lazy(() => import('../pages/NewsPage'))
const NewsListPage = lazy(() => import('../pages/NewsListPage'))
const CatalogPage = lazy(() => import('../pages/CatalogPage'))

class ErrorBoundary extends React.Component {

  state = {
    hasError: false
  }

  static getDerivedStateFromError (error) {
    return { hasError: true }
  }

  componentDidCatch (error, errorInfo) {
    // Pass
  }

  render () {
    if (this.state.hasError) {
      return (
        <Page>
          <h1>Что-то пошло не так.</h1>
          Мы работаем над устранением ошибки!
        </Page>
      )
    }

    return this.props.children
  }
}

export default () => {
  const dispatch = useDispatch()
  const orderProcessing = useSelector(state => state.order.processing)

  useEffect(() => {
    dispatch(fetchSidebarCategories())
  }, [dispatch])

  useEffect(() => {
    window.setTimeout(() => {
      injectScript('//code.jivosite.com/script/widget/YbxAUfzORj')
    }, 1000)
  }, [])

  return (
    <>
      <Helmet>
        <title>Самовары Ру</title>
      </Helmet>

      <Sidebar/>

      <Wrapper>
        <Header/>
        <Container>
          <ErrorBoundary>
            <Suspense fallback={<Loading/>}>
              <Switch>
                <Route path="/good/:id/" component={(props) => <ProductPage key={props.match.params.id} {...props} />}/>
                <Route path="/category/:id/" component={(props) => <CategoryPage key={props.match.params.id} {...props} />}/>
                <Route exact path="/article/34/" component={SearchPage}/>
                <Route exact path="/article/:id/" component={props => <ArticlePage key={props.match.params.id} {...props} />}/>
                <Route exact path="/contacts/" component={props => <NewsEntryPage key={'contacts'} hardcodedId={'contacts'} {...props} />}/>
                <Route exact path="/about/" component={props => <NewsEntryPage key={148} hardcodedId={148} {...props} />}/>
                <Route exact path="/news/:id/" component={props => <NewsEntryPage key={props.match.params.id} {...props} />}/>
                <Route exact path="/news/" component={NewsListPage}/>
                <Route exact path="/order/:id/" component={OrderPage}/>
                <Route exact path="/catalog/" component={CatalogPage}/>
                <Route exact path="/order/" component={CartPage}/>
                <Route exact path="/" component={HomePage}/>
                <Route component={NoMatch}/>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </Container>
        <Footer/>
        {/*<Subscription />*/}

        <Notification/>
        <QuickOrder />

        {orderProcessing && <Loading fade/>}
      </Wrapper>
    </>
  )
}
