import { SET_CITY } from './cart'

export const FETCH_DELIVERY_TYPES = 'FETCH_DELIVERY_TYPES'
export const SET_DELIVERY_TYPES = 'SET_DELIVERY_TYPES'

const initialState = {
  loading: false,
  empty: true,
  variants: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DELIVERY_TYPES:
      return {
        ...state,
        loading: true
      }

    case SET_CITY:
      return {
        ...initialState
      }

    case SET_DELIVERY_TYPES:
      return {
        ...state,
        loading: false,
        empty: false,
        variants: action.types,
      }

    default:
      return state
  }
}