import { LOCATION_CHANGE } from './location'

export const FETCH_SIDEBAR_CATEGORIES = 'FETCH_SIDEBAR_CATEGORIES'
export const SET_SIDEBAR_CATEGORIES = 'SET_SIDEBAR_CATEGORIES'

const defaultState = {
	loading: false,
	visible: false,
	categories: []
}

const sidebar = (state = defaultState, action) => {
	switch (action.type) {
		case FETCH_SIDEBAR_CATEGORIES:
			return {
				...state,
				loading: true
			}

		case SET_SIDEBAR_CATEGORIES:
			return {
				...state,
				loading: false,
				categories: action.categories
			}

		case 'SHOW':
			return {
				...state,
				visible: true
			}

		case LOCATION_CHANGE:
		case 'HIDE':
			return {
				...state,
				visible: false
			}

		default:
			return state
	}
}

export default sidebar