import axios, { AxiosInstance } from 'axios'
import { FETCH_SIDEBAR_CATEGORIES, SET_SIDEBAR_CATEGORIES } from './reducers/sidebar'
import { SET_CATEGORY_BY_ID, SET_GOODS_BY_QUERY } from './reducers/categories'
import { FETCH_PRODUCT_DATA, SET_PRODUCT_DATA } from './reducers/products'
import { FETCH_DELIVERY_TYPES, SET_DELIVERY_TYPES } from './reducers/delivery'
import { ADD_TO_CART, SET_DELIVERY_TYPE } from './reducers/cart'
import { deliveryTypeSelector } from '../pages/CartPage/selectors'
import {
  ORDER_ERROR,
  ORDER_FETCH_ERROR,
  ORDER_FETCHED,
  ORDER_FETCHING,
  ORDER_PROCESSED,
  PROCESSING_ORDER
} from './reducers/order'
import { SET_SEARCH_RESULT } from './reducers/search'
import { SET_CATALOG } from './reducers/catalog'

const storage = (() => {
  try {
    localStorage.setItem('xxx', 1)
    localStorage.removeItem('xxx')
  } catch (e) {
    return () => undefined
  }

  return (key: string, value: any) => {
    if (value === undefined) {
      return JSON.parse(localStorage.getItem(key))
    }

    localStorage.setItem(key, JSON.stringify(value))

    return value
  }
})()

/**
 * Клиент для работы с сервером
 * @type {AxiosInstance}
 */
export const client: AxiosInstance = axios.create({
  baseURL: 'https://samovary.ru/api/',
  responseType: 'json',
  withCredentials: true
})

/**
 * Проставить категории для меню
 * @param categories
 * @returns {{categories: *, type: string}}
 */
export function setSidebarCategories (categories) {
  return {
    type: SET_SIDEBAR_CATEGORIES,
    categories,
  }
}

const key = (function () {
  const d = new Date().getDate()
  
  return function key(str) {
    return str + '_' + d
  }
})()

/**
 * Загрузить категории
 * для меню сайта
 */
export function fetchSidebarCategories () {
  let categories = storage(key('categories'))

  // if (categories) {
  //   return setSidebarCategories(categories)
  // }

  return dispatch => {
    dispatch({
      type: FETCH_SIDEBAR_CATEGORIES,
    })

    return client.get('/categories/').then(response => dispatch(setSidebarCategories(
      storage(key('categories'), response.data),
    )))
  }
}

///

export function showSidebar () {
  return {
    type: 'SHOW',
  }
}

export function hideSidebar () {
  return {
    type: 'HIDE',
  }
}

////

export function setPageData (pageId, data) {
  return {
    type: 'SET_PAGE_DATA',
    pageId,
    data,
  }
}

export function fetchPageData (pageId, props = {}) {
  return dispatch => {
    dispatch({
      type: 'FETCH_PAGE_DATA',
      pageId,
    })

    return client.post('/pagedata/', { pageId, ...props }).then(response => dispatch(setPageData(pageId, response.data)))
  }
}

////

export function setCategoryById (categoryId, data) {
  return {
    type: SET_CATEGORY_BY_ID,
    categoryId, data,
  }
}

export function setGoodsByQuery (query, goods) {
  return {
    type: SET_GOODS_BY_QUERY,
    query, goods,
  }
}

export function goodsKey (categoryId: number, parentId: number, search: string): string {
  return `${categoryId}/${parentId}${search}`
}

export function fetchGoodsByQuery (categoryId, parentId, search) {
  return async (dispatch, getState) => {
    const { categories: { goods } } = getState()
    const key = goodsKey(categoryId, parentId, search)

    if (!goods[key]) {
      const params = new URLSearchParams(search)

      params.append('id', categoryId)
      params.append('parent_id', parentId)

      try {
        const response = await client.get(`/goods/`, { params })

        dispatch(setGoodsByQuery(key, response.data))
      } catch (e) {
        dispatch(setGoodsByQuery(key, []))
      }
    }
  }
}

export function fetchCategoryIfNeeded (categoryId, search) {
  return async (dispatch, getState) => {
    const { categories: { categories } } = getState()

    if (!categories[categoryId]) {
      try {
        const response = await client.post(`/category/`, { id: categoryId })

        dispatch(
          setCategoryById(categoryId, response.data)
        )
      } catch (e) {
        dispatch(
          setCategoryById(categoryId, {})
        )
      }
    }
  }
}

export function setProductData (id, data) {
  return {
    type: SET_PRODUCT_DATA, id, data
  }
}

export function fetchProductIfNeeded (id) {
  return async (dispatch, getState) => {
    const { products } = getState()

    if (!products[id]) {
      try {
        dispatch({
          type: FETCH_PRODUCT_DATA, id
        })

        const response = await client.get('/good/', { params: { id } })

        dispatch(setProductData(id, response.data))
      } catch (e) {
        dispatch(setProductData(id, {}))
      }
    }
  }
}

///////

export function addProductToCart (id, value) {
  return async (dispatch, getState) => {
    dispatch({
      type: ADD_TO_CART,
      productId: id,
      directValue: value
    })

    // Сформируем запрос за вариантами доставки
    // так как после изменений состояния
    // стоимость доставки может поменятся
    const { request } = deliveryTypeSelector(getState())

    // Запрашиваем расчет доставки
    await fetchDeliveryTypes(request)(dispatch, getState)
  }
}

///////

export function setDeliveryType (id) {
  return {
    type: SET_DELIVERY_TYPE,
    id
  }
}

export function fetchDeliveryTypes (request) {
  return async (dispatch) => {
    dispatch({ type: FETCH_DELIVERY_TYPES })

    try {
      const response = await client.post('/delivery/', request)

      dispatch({
        type: SET_DELIVERY_TYPES,
        types: response.data
      })
    } catch (e) {
      dispatch({
        type: SET_DELIVERY_TYPES,
        types: []
      })
    }
  }
}

//////////////

export function fetchOrder (token) {
  return async (dispatch) => {
    dispatch({
      type: ORDER_FETCHING,
    })

    try {
      const response = await client.post('/orderStatus/', {
        token
      })

      dispatch({
        type: ORDER_FETCHED,
        order: response.data
      })

      return true
    } catch (e) {
      dispatch({
        type: ORDER_FETCH_ERROR
      })

      return false
    }
  }
}

export function processOrder () {
  return async (dispatch, getState) => {
    dispatch({
      type: PROCESSING_ORDER
    })

    try {
      const state = getState()
      const { data } = await client.post('/order/', {
        ...state.cart.client,
        ...state.cart.delivery,
        products: state.cart.quantityById,
        delivery: state.cart.deliveryType,
        payment: state.cart.payment
      })

      dispatch({
        type: ORDER_PROCESSED,
        ...data
      })

      return data.order.id
    } catch (e) {
      dispatch({
        type: ORDER_ERROR,
        errors: e.response.data
      })

      return null
    }
  }
}

export function processQuickOrder (productId, phone) {
  return async (dispatch) => {
    dispatch({
      type: 'SUBMIT_QUICK_ORDER',
    })

    await client.post('/quick_order/', {
      phone, product: productId
    })

    dispatch({
      type: 'SUCCESS_QUICK_ORDER',
    })
  }
}

export function searchProducts (query) {
  return async (dispatch) => {
    const { data: products } = await client.get('/search/', {
      params: new URLSearchParams(query)
    })

    dispatch({
      type: SET_SEARCH_RESULT,
      query, products
    })
  }
}

export async function fetchArticleEntry (id) {
  const { data: entry } = await client.get('/article/', {
    params: {
      id
    }
  })

  return entry
}

export async function fetchNewsEntry (id) {
  const { data: entry } = await client.get('/news/', {
    params: {
      id
    }
  })

  return entry
}

export async function fetchNewsList (page = 0) {
  const { data } = await client.get('/news_list/')

  return data
}

export function fetchCatalog () {
  return async dispatch => {
    const { data: { categories, texts } } = await client.get('/catalog/')

    dispatch({
      type: SET_CATALOG,
			categories, texts
    })
  }
}
