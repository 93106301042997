import { SET_DELIVERY_TYPES } from './delivery'
import countries from '../../data/countries'
import { ORDER_ERROR, ORDER_PROCESSED } from './order'
import { Action } from 'redux'

export const UPDATE_CLIENT = 'UPDATE_CLIENT'
export const SET_COUNTRY = 'SET_COUNTRY'
export const SET_CITY = 'SET_CITY'
export const ADD_TO_CART = 'ADD_TO_CART'
export const SET_DELIVERY_TYPE = 'SET_DELIVERY_TYPE'
export const SET_PAYMENT = 'SET_PAYMENT'
export const SET_PICKPOINT = 'SET_PICKPOINT'

const initialState = {
  addedIds: [],
  quantityById: {},
  client: {
    name: '',
    phone: '',
    comment: '',
    email: '',
  },
  deliveryType: 0,
  payment: 2,
  delivery: {
    country: countries[0],
    city: '',
    pickpoint: '',
  },
  errors: {}
}

const cart = (state = initialState, action : Action | {}) => {
  switch (action.type) {
    case SET_PICKPOINT: {
      return {
        ...state,
        delivery: {
          ...state.delivery,
          pickpoint: `${action.pickpoint.shortaddress} (${action.pickpoint.name})`
        }
      }
    }

    case SET_PAYMENT:
      return {
        ...state,
        payment: action.id
      }

    case SET_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: action.id,
      }

    case SET_DELIVERY_TYPES:
      if (action.types.length > 0 && (!state.deliveryType || state.deliveryType === 0)) {
        state = {
          ...state,
          deliveryType: action.types[0].id
        }
      }

      return state

    case SET_CITY:
      return {
        ...state,
        deliveryType: 0,
        delivery: {
          ...state.delivery,
          city: action.city
        }
      }

    case SET_COUNTRY:
      return {
        ...state,
        delivery: {
          ...state.delivery,
          country: action.country
        }
      }

    case UPDATE_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          [action.prop]: action.value
        }
      }

    case ADD_TO_CART:
      // Удаляем продукт
      if ('directValue' in action && action.directValue === 0) {
        const index = state.addedIds.indexOf(action.productId)

        if (index < 0) {
          return state
        }

        const { [action.productId]: removedValue, ...withoutProduct } = state.quantityById

        return {
          ...state,
          quantityById: withoutProduct,
          addedIds: [
            ...state.addedIds.slice(0, index),
            ...state.addedIds.slice(index + 1)
          ]
        }
      }

      // Товар уже есть в наличии
      if (state.addedIds.indexOf(action.productId) >= 0) {
        return {
          ...state,
          quantityById: {
            ...state.quantityById,
            [action.productId]: action.directValue ? action.directValue :
              state.quantityById[action.productId] + (action.value || 1)
          }
        }
      }

      return {
        ...state,
        addedIds: [...state.addedIds, action.productId],
        quantityById: {
          ...state.quantityById,
          [action.productId]: 1
        }
      }

    case ORDER_PROCESSED:
      return {
        ...state,
        errors: {}
      }

    case ORDER_ERROR:
      return {
        ...state,
        errors: action.errors
      }

    default:
      return {
        ...initialState,
        ...state
      }
  }
}

export default cart