import React, { useEffect } from 'react'
import serialize from 'form-serialize'
import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router'

import { Input } from './shared'
import { hideSidebar } from '../redux/actions'
import store from '../redux/store'

const SidebarWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	width: 280px;
	background: #ffd900;
	overflow: auto;
	overflow-scrolling: touch;
	overscroll-behavior-y: contain;
	transition: transform .2s ease;
	transform: translateX(-280px);
	z-index: 21;
	padding-bottom: 5rem;
	
	${props => props.visible && css`transform: translateX(0);`}
`

const SearchForm = styled(withRouter(({ history, children, className }) => {
	const onSubmit = e => {
		e.preventDefault()
		history.push('/article/34/?' + serialize(e.currentTarget))
	}

	return (
		<form action="" className={className} onSubmit={onSubmit}>
			{children}
		</form>
	)
}))`
	margin: .71428571rem .71428571rem 1rem;
	
	${Input} {
		border-color: #e7c500;
	}
`

const StyledLink = styled(Link)`
	padding: .78125rem .71428571rem;
	font-size: .85714286rem;
	font-weight: bold;
	text-decoration: none;
	text-transform: uppercase;
	color: #000;
	display: block;
`

const SidebarOverlay = styled((props) => {
	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = ''
		}
	})

	return <div {...props} />
})`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, .1);
	cursor: default;
	z-index: 20;
`

const CatalogRow = styled.div`
	position: relative;
	
	&:after {
		content: '›';
		position: absolute;
		top: .71428571rem;
		right: .71428571rem;
		opacity: .7;
	}
`

const CatalogLink = styled(Link)`
	font-size: 1.14285714rem;
	font-weight: normal;
	text-decoration: none;
	color: #000;
	border-top: 1px solid #e7c500;
	position: relative;
	display: block;
	padding: .71428571rem 2.14285714rem .71428571rem .71428571rem;
`

const CatalogList = styled.div`
	margin-bottom: 1rem;

	& & ${CatalogLink} {
		padding-left: 1.5rem;
	}
`

type CategoryRow = {
	id: number;
	name: string;
	cats: Array<CategoryRow>;
}

const renderCategories = (categories: Array<CategoryRow>) => (
	<CatalogList>
		{categories.map((category: CategoryRow) => {
			return (
				<CatalogRow key={category.name}>
					<CatalogLink onClick={() => store.dispatch(hideSidebar())} to={`/category/${category.id}/`}>{category.name}</CatalogLink>

					{category.cats && category.cats.length > 0 && renderCategories(category.cats)}
				</CatalogRow>
			)
		})}
	</CatalogList>
)

const Sidebar = (props: { visible: boolean, hideSidebar: () => void, categories: Array<CategoryRow> }) => (
	<>
		<SidebarWrapper visible={props.visible}>
			<SearchForm>
				<Input name='artikul' placeholder='Поиск по сайту'/>
			</SearchForm>

			<StyledLink onClick={() => store.dispatch(hideSidebar())} to='/catalog/' title='Каталог'>Каталог товаров</StyledLink>

			{renderCategories(props.categories || [])}

			<StyledLink to="/about/">О компании</StyledLink>
			<StyledLink to="/news/78/">Оплата и доставка</StyledLink>
			<StyledLink to="/news/154/">Гарантия</StyledLink>
			<StyledLink to="/news/142/">Вопросы и ответы</StyledLink>
			<StyledLink to="/category/44/all/">Скидки</StyledLink>
			<StyledLink to="/contacts/">Контакты</StyledLink>
		</SidebarWrapper>

		{props.visible && (
			<SidebarOverlay onClick={props.hideSidebar}/>
		)}
	</>
)

export default Sidebar