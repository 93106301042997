export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT';

export default (state = {}, action) => {
	switch (action.type) {
		case SET_SEARCH_RESULT:
			return {
				...state,
				[action.query]: action.products
			}

		default:
			return state
	}
}