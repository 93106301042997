export const PROCESSING_ORDER = 'PROCESSING_ORDER'
export const ORDER_PROCESSED = 'ORDER_PROCESSED'
export const ORDER_FETCHING = 'ORDER_FETCHING'
export const ORDER_FETCHED = 'ORDER_FETCHED'
export const ORDER_FETCH_ERROR = 'ORDER_FETCH_ERROR'
export const ORDER_ERROR = 'ORDER_ERROR'

const initialState = {
  processing: false,
  token: null,
  order: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PROCESSING_ORDER:
      return {
        ...state,
        processing: true,
        errors: {}
      }

    case ORDER_ERROR:
      return {
        ...state,
        processing: false,
      }

    case ORDER_FETCHING:
      return {
        ...state,
        fetching: true,
      }

    case ORDER_FETCHED:
      return {
        ...state,
        fetching: false,
        order: action.order
      }

    case ORDER_FETCH_ERROR:
      return {
        ...state,
        fetching: false,
        token: null
      }

    case ORDER_PROCESSED:
      return {
        ...state,
        processing: false,
        token: action.token,
        order: action.order
      }

    default:
      return {
        ...initialState,
        ...state
      }
  }
}