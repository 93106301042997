export default class FakeJQuery {
	constructor (selector) {
		this.selector = selector
	}

	click () {
		return this
	}

	wrap () {
		return this
	}

	parent () {
		return this
	}

	mouseover () {
		return this
	}

	mouseout () {
		return this
	}
}
