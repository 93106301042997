import React, { useCallback, useRef, useState, useLayoutEffect } from 'react'
import { Link, Switch, Route } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Icon, Input, SearchForm } from './shared'

const HeaderIcon = styled(Icon)`
	z-index: 1;

	${props => props.count && css`
		&:after {
			content: '${props => props.count}';
			font-style: normal;
			background: red;
			font-size: .6rem;
			color: #FFF;
			border-radius: 100%;
			width: 1.1rem;
			height: 1.1rem;
			line-height: 1.1rem;
			text-align: center;
			position: absolute;
			top: 1rem;
			left: 1rem;
			margin-top: 1rem;
			margin-left: 1rem;
		}
	`}
`
const HeaderWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	width: 100%;
	max-width: 720px;
	z-index: 19;
	background: #FFF;
	transition: box-shadow .5s ease;
	
	${props => props.floating && css`
		box-shadow: 0 0 10px #ccc;
	`}
	
	${HeaderIcon} {
		padding: 1rem;
		font-size: 1.85rem;
	}
`

const LogoSpacer = styled.div`
	flex: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`

const StyledLogo = styled.img`
	height: 2.35rem;
`

const StyledSearchForm = styled(SearchForm)`
	margin: 0 1rem 1rem;
`

const HeaderWrapperSpacer = styled.div`
	height: ${props => props.height}px
`

const HeaderButtons = styled.div`
	display: flex;
	align-items: center;
	position: relative;
	z-index: 1;
	
	a {
		display: block;
		cursor: pointer;
	}
`

const Header = (props) => {
	const [floating, setFloating] = useState(false)
	const [height, setHeight] = useState(0)
	const header = useRef(null)
	const scrollCallback = useCallback(() => {
		setFloating(window.scrollY > 0)
	}, [setFloating])

	useLayoutEffect(() => {
		setHeight(header.current.getBoundingClientRect().height)

		window.addEventListener('scroll', scrollCallback, {
			passive: true
		})

		return () => {
			window.removeEventListener('scroll', scrollCallback)
		}
	}, [scrollCallback, header, setHeight])

	return (
		<>
			<HeaderWrapper floating={floating} ref={header}>
				<HeaderIcon onClick={props.showSidebar} menu/>

				<LogoSpacer>
					<Link to='/'>
						<StyledLogo alt='Самовары.ру' src='//samovary.ru/images/samovary-logo.jpg'/>
					</Link>
				</LogoSpacer>

				<HeaderButtons>
					<a target='_blank' rel='noopener noreferrer' href="https://api.whatsapp.com/send?phone=79035659170"><img width={40} height={40} alt='Самовары в WhatsApp' src={require('../assets/icons8-whatsapp.svg')}/></a>
					<Link to='/order/'><HeaderIcon count={props.count} basket/></Link>
				</HeaderButtons>
			</HeaderWrapper>
			<HeaderWrapperSpacer height={height}/>

			<StyledSearchForm>
				<Switch>
					<Route exact path="/article/34/" component={({ history: { location: { search } } }) => {
						const value = new URLSearchParams(search)

						return <Input name='artikul' defaultValue={value.get('artikul')} placeholder='Поиск по сайту'/>
					}}/>
					<Route component={() => <Input name='artikul' placeholder='Поиск по сайту'/>}/>
				</Switch>
			</StyledSearchForm>
		</>
	)
}

export default Header