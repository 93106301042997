import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import styled from 'styled-components';
import LazyLoad from 'react-lazy-load';

import { fetchProductIfNeeded } from '../redux/actions';
import { Button, Row, Col, Section } from '../components/shared';
import { ADD_TO_CART } from '../redux/reducers/cart';
import NoMatch from '../components/NoMatch';
import Page from '../components/Page';
import Breadcrumbs from '../components/Breadcrumbs';
import Loading from '../components/Loading';
import { SHOW_NOTIFICATION } from '../redux/reducers/notification';
import Product from '../components/Product';
import Modal, { ModalTitle } from '../components/Modal';
import { JsonLd } from 'react-schemaorg';
import type { ProductInterface } from '../types';

const MainImage = styled.div`
  text-align: center;
  
  img {
    display: inline-block !important;
    vertical-align: top;
  }
`;

const Stats = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 1rem -1rem;
  padding: .5rem 1rem;
  border-top: 1px solid #f3f3f3;
`;

const OrderCounter = styled.div`
  display: inline-block;
  background: #f4f4f4;
  padding: 6px 8px;
  color: #222;
  margin: .5rem .5rem 0 0;
`;

const SliderPointer = styled.div`
  text-align: center;
  opacity: .8;
  margin: 1rem 0;
  font-size: .9rem;
`;

const ScrollTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
};

// const AndGift = styled.span`
// 	margin-left: 2.5rem;
// 	display: inline-block;
// 	font-size: 1.2rem;
// 	position: relative;
// 	padding-left: 55px;
//
// 	&:before {
// 		width: 46px;
// 		height: 35px;
// 		line-height: 45px;
// 		background: url(https://samovary.ru/shopping-cart/media/css_sprites.png) -204px -248px;
// 		content: '+';
// 		text-indent: -1rem;
// 		margin-right: 12px;
// 		display: inline-block;
// 		position: absolute;
// 		bottom: 0;
// 		left: 0;
//   }
// `

const Price = styled.div`
	font-size: 2rem;
	font-weight: bold;
	
	&:after {
		content: ' ₽'
	}
`;

const PrevPrice = styled.div`
	font-size: 1.5rem;
	font-weight: normal;
	text-decoration: line-through;
	text-decoration-color: red;
	text-decoration-width: 1px;
	
	&:after {
		content: ' ₽'
	}
`;

const Gifts = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: visible;
  margin: -1rem;
  padding: 1rem 1rem 2rem;
  
  &:after {
    content: '';
    flex: 0 0 1rem;
    width: 1rem;
    height: 1rem;
    display: block;
  }
`;

const Gift = styled(({ className, productGift }) => {
  const [showModal, setShowModal] = useState(false);

  const onClick = useCallback(() => setShowModal(true), [setShowModal]);
  const onDismiss = useCallback(() => setShowModal(false), [setShowModal]);

  return (
    <>
      <div className={className} onClick={onClick}>
        {productGift.title}

        <Gift.Wrapper>
          <picture>
            <source srcSet={productGift.picture.replace('.jpg', '.webp')} type="image/webp"/>
            <img alt={productGift.title} src={productGift.picture}/>
          </picture>
        </Gift.Wrapper>
      </div>

      {showModal && (
        <Modal onDismiss={onDismiss}>
          <ModalTitle style={{ textAlign: 'center' }}>{productGift.title}</ModalTitle>
          <picture>
            <source srcSet={productGift.picture.replace('.jpg', '.webp')} type="image/webp"/>
            <img style={{ margin: '0 auto', display: 'block', maxWidth: '100%' }} alt={productGift.title}
                 src={productGift.large}/>
          </picture>
        </Modal>
      )}
    </>
  );
})`
  border: 1px solid #f3f3f3;
  padding: 1rem;
  border-radius: 1rem;
  box-shadow: 0 5px 10px -8px #ccc;
  text-align: center;
  flex: 0 0 45%;
  display: flex;
  flex-direction: column;
  
  &:not(:last-child) {
    margin-right: 1rem;
  }
  
  img {
    margin-top: .5rem;
    vertical-align: top;
  }
`;

Gift.Wrapper = styled(LazyLoad)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;
//
// const DiscountPeriod = styled.div`
// 	font-size: .8rem;
// 	margin: .5rem 0 1rem;
// `
//
// const DiscountPrice = styled.span`
// 	margin-left: 1rem;
// `

const DiscountPercent = styled.span`
	display: inline-block;
	width: 2.4rem;
	height: 2.4rem;
	line-height: 2.4rem;
	text-align: center;
	margin-left: 1rem;
	background: rgba(255, 209, 0, 0.2);
	border-radius: 100%;
	font-size: .8rem;
`;

export const SliderInner = styled.div`
	width: 300px;
	padding: 0 .5rem;
`;

const Navigation = styled.div`
	display: flex;
	justify-content: space-between;
`;

const PrevPage = styled(Link)`
	background: #f3f3f3;
	border-radius: 3px;
	padding: .5rem 1rem;
	color: #000;
`;

function checkDiscount (meta: ProductInterface): boolean {
  return meta.has_discount;
}

let mneniya = false;

const Reviews = ({ meta }: { meta: ProductInterface }) => {
  useEffect(() => {
    if (! mneniya) {
      const script = document.createElement('script');
      script.src = '//cdn.mneniya.pro/widgetscipts/samovaryru/mp-widget.js';
      script.type = 'text/javascript';
      script.onload = function () {
        mneniya = true
      };

      document.getElementsByTagName('head')[0].appendChild(script);
    } else {
      window.mpLoadWidget();
    }
  }, [meta.id]);

  return (
    <Section>
      <h2>Отзывы</h2>

      <div className="mp-productName" style={{ display: 'none' }}>{meta.name}</div>
      <div className="mp-widget" data-mp-productskus={meta.id} data-mp-widget-type="Product"/>
    </Section>
  );
};

const productSelector = createSelector(
  (state) => state.products,
  (state, id) => id,
  (product, id) => product[id]
);

function pluralForm ($n, $forms) {
  return $n % 10 === 1 && $n % 100 !== 11 ? $forms[0] : (
    $n % 10 >= 2 && $n % 10 <= 4 && ($n % 100 < 10 || $n % 100 >= 20) ? $forms[1] : $forms[2]
  );
}

function formatDate (rawDate) {
  const date: Date = new Date(Date.parse(rawDate));

  return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
}

function JsonLdProduct ({ product }) {
  const item = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    'name': product.name,
    'image': product.pictures.map(size => {
      return `https://samovary.ru/${size}`.replace('.jpg', '.webp');
    }),
    'description':
      `<div>${product.descr_short}</div>` +
      `<div>${product.descr}</div>`,
    'brand': {
      '@type': 'Thing',
      'name': product.brand
    },
    'review': {
      '@type': 'Review',
      'reviewRating': {
        '@type': 'Rating',
        'ratingValue': product.review_score,
        'bestRating': '5'
      },
      'author': {
        '@type': 'Person',
        'name': product.review_author
      }
    },
    'aggregateRating': {
      '@type': 'AggregateRating',
      'ratingValue': product.score,
      'reviewCount': product.score_hits
    },
    'offers': {
      '@type': 'Offer',
      'url': `https://samovary.ru/good/${product.id}/`,
      'priceCurrency': 'RUB',
      'price': product.price,
      'priceValidUntil': '2020-11-05',
      'itemCondition': 'https://schema.org/NewCondition',
      'availability': product.amount > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
      'seller': {
        '@type': 'Organization',
        'name': 'Тульские самовары'
      }
    }
  };

  if (!product.review_author) {
    delete item.review;
  }

  return (
    <JsonLd item={item}
    />
  );
}

const ProductPage = ({ match: { params: { id } } }) => {
  const [currentSlide, setCurrentSlide] = useState(1);
  const product = useSelector(state => productSelector(state, id));
  const dispatcher = useDispatch();

  const showQuickOrder = useCallback(() => {
    dispatcher({
      type: 'OPEN_QUICK_ORDER',
      product: product
    });
  }, [dispatcher, product]);

  const onAddToCart = useCallback(() => {
    dispatcher({
      type: ADD_TO_CART,
      productId: id
    });

    dispatcher({
      type: SHOW_NOTIFICATION,
    });
  }, [dispatcher, id]);

  if (!product) {
    dispatcher(fetchProductIfNeeded(id));
  }

  if (!product || product.loading) {
    return (
      <>
        <ScrollTop/>
        <Loading/>
      </>
    );
  }

  if (!product.meta) {
    return <NoMatch/>;
  }

  const {
    breadcrumbs,
    product_gifts,
    viewing_today,
    viewing_now,
    bought_counter,
    texts,
    meta,
    btw_goods,
    similar_goods,
    recommended_goods_2,
    meta_prev,
    meta_next
  } = product;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (index) => {
      setCurrentSlide(index + 1);
    }
  };

  return (
    <>
      <Helmet>
        <title>{texts.title}</title>
        <meta name='description' content={texts.description}/>
        <meta name='keywords' content={texts.keywords}/>
      </Helmet>

      <JsonLdProduct product={meta}/>

      <ScrollTop/>

      <Breadcrumbs breadcrumbs={breadcrumbs}/>

      <Navigation style={{ marginBottom: '1rem' }}>
        {meta_prev ? <PrevPage to={`/good/${meta_prev.id}`}>❮ Предыдущий</PrevPage> : <span/>}
        {meta_next ? <PrevPage to={`/good/${meta_next.id}`}>Следующий ❯</PrevPage> : <span/>}
      </Navigation>

      <Page>
        <h1 className="mp-prod_name">{meta.name}</h1>

        <Slider {...settings}>
          {meta.pictures.map((size, index) => {
            return (
              <MainImage key={index}>
                <picture>
                  <source srcSet={`https://samovary.ru/${size}`.replace('.jpg', '.webp')}
                          type="image/webp"/>
                  <img alt={meta.name} height={200} src={`https://samovary.ru/${size}`}/>
                </picture>
              </MainImage>
            );
          })}
        </Slider>

        <SliderPointer>
          {currentSlide} из {meta.pictures.length}
        </SliderPointer>

        <Stats>
          {viewing_now > 0 && <OrderCounter>Сейчас смотрят: {viewing_now}</OrderCounter>}
          <OrderCounter>Купили {bought_counter} {pluralForm(bought_counter, ['раз', 'раза', 'раз'])}</OrderCounter>
          {viewing_today > 0 && viewing_today !== viewing_now &&
          <OrderCounter>Посмотрели сегодня {viewing_today}</OrderCounter>}
        </Stats>

        <Row style={{ marginBottom: '1rem' }}>
          <Col flexGrow alignSelf='center'>
            {meta.is_hidden === 0 && checkDiscount(meta) && (
              <Row style={{ marginBottom: '.5rem' }}>
                <Col alignSelf='center'><PrevPrice
                  as='span'>{meta.price_old.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</PrevPrice></Col>
                <Col><DiscountPercent>-{Math.ceil(100 - (meta.price / meta.price_old) * 100)}%</DiscountPercent></Col>
              </Row>
            )}

            {meta.is_hidden === 1 && <p>Нет в наличии, последняя цена продажи: </p>}

            <Price style={{ lineHeight: '3.3rem' }}>{meta.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</Price>
          </Col>
          {meta.is_hidden === 0 && meta.amount > 0 && (
            <Col alignSelf='flex-end'>
              <Button onClick={onAddToCart}>Добавить в корзину</Button>
            </Col>
          )}
        </Row>

        {!meta.is_hidden && meta.amount > 0 && (
          <Button invert center onClick={showQuickOrder}>Купить в 1 клик</Button>
        )}

        {!meta.is_hidden && (
          <p style={{ textAlign: 'center' }}>
            <small>
              {checkDiscount(meta) && meta.price_old_start !== '0000-00-00' && meta.price_old_till !== '0000-00-00' && (<>
                Специальная цена
                с&nbsp;{formatDate(meta.price_old_start)} по&nbsp;{formatDate(meta.price_old_till)}<br/>
              </>)}
  
              {meta.amount === 0 && 'Временно нет в наличии'}
              {meta.amount > 1 && (
                <>Есть в наличии,
                  всего {meta.amount} {pluralForm(meta.amount, ['штука', 'штуки', 'штук'])}</>
              )}
              {meta.amount === 1 && 'Последний шанс! В наличии остался только 1 экземпляр'}
            </small>
          </p>
        )}

        <Section>
          <strong>Доставка:</strong>
          {meta.primary_region ? (
            <ul>
              <li>Курьером по Москве и Санкт-Петербургу, 1-2 дня</li>
              <li>Самовывоз из магазина, скидка 3%</li>
              <li>Самовывоз из пунктов СДЕК, ПикПоинт, 1-2 дня</li>
              <li>Бесплатная доставка при заказе от 5000 руб.</li>
              <li>Платная доставка - 450 руб.</li>
            </ul>
          ) : (
            <ul>
              <li>Курьером по адресу доставки, 3-8 дней.</li>
              <li>Самовывоз из пунктов СДЕК, ПикПоинт, Почта России.</li>
              <li>Доставка за границу с предоплатой - по тарифам и срокам EMS, СДЕК, 10-20 дней</li>
              <li>Платная доставка РФ по тарифам СДЕК - 250-1200 руб., зависит от города</li>
            </ul>
          )}

          <strong>Гарантия:</strong>
          <ul>
            <li>Гарантийный срок - 3 года</li>
            <li>Возврат денег в течение 30 дней по первому требованию</li>
          </ul>
          <strong>Удобная оплата:</strong>
          <ul>
            <li>Наличными при получении или картой онлайн</li>
            <li>Бесплатная консультация:<br/>
              Москва +7 (495)744-1041, СПБ +7(812)313-2450
              Бесплатный звонок по РФ 8-800-775-0591
              WhatsApp:&nbsp;<a href="https://api.whatsapp.com/send?phone=79035659170" style={{
                textDecoration: 'underline',
                textDecorationStyle: 'dotted',
                color: '#333'
              }}>+7&nbsp;(903)&nbsp;565-9170</a>
            </li>
          </ul>
        </Section>

        <Section>
          <h2>Описание товара</h2>

          <div dangerouslySetInnerHTML={{ __html: meta.descr_short }}/>
          <div dangerouslySetInnerHTML={{ __html: meta.descr }}/>
        </Section>

        {product_gifts.length > 0 && (
          <Section>
            <h2><a href='##' onClick={showQuickOrder}>Заказывайте сейчас</a>, вас ждут подарки</h2>

            <Gifts>
              {product_gifts.map(productGift => <Gift key={productGift.title}
                                                      productGift={productGift}/>)}
            </Gifts>
          </Section>
        )}

        <Navigation>
          {meta_prev ? <PrevPage to={`/good/${meta_prev.id}`}>❮ Предыдущий</PrevPage> : <span/>}
          {meta_next ? <PrevPage to={`/good/${meta_next.id}`}>Следующий ❯</PrevPage> : <span/>}
        </Navigation>

        {navigator.userAgent.match(/GoogleBot/i) === null && (
          <Reviews meta={meta}/>
        )}

        <Section>
          <h2>Похожие товары</h2>
          {/*<ProductGrid>*/}
          <Slider {...{
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          }}>
            {similar_goods.map(product => <SliderInner key={product.id}><Product
              good={product}/></SliderInner>)}
          </Slider>
          {/*</ProductGrid>*/}
        </Section>

        <Section>
          <h2>Сопутствующие товары</h2>
          {/*<ProductGrid>*/}
          <Slider {...{
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
          }}>
            {btw_goods.map(product =>
              <SliderInner key={product.id}>
                <Product good={product}/>
              </SliderInner>
            )}
          </Slider>
          {/*</ProductGrid>*/}
        </Section>

        {recommended_goods_2.length > 0 && (
          <Section>
            <h2>Дополнительно можно приобрести</h2>

            {[1, 2, 3, 5].indexOf(meta.categories[0].id) >= 0 && (
              <h3>Мы предоставим Вам скидку на товар из аксессуаров при заказе самовара.
                Звоните&nbsp;+7(495)744-1041</h3>
            )}

            {/*<ProductGrid>*/}
            <Slider {...{
              dots: false,
              infinite: true,
              speed: 500,
              slidesToShow: 1,
              slidesToScroll: 1
            }}>
              {recommended_goods_2.map(product => <SliderInner key={product.id}><Product
                good={product}/></SliderInner>)}
            </Slider>
            {/*</ProductGrid>*/}
          </Section>
        )}

      </Page>
    </>
  );
};

export default ProductPage;