import { createSelector } from 'reselect'

export const cartSelector = createSelector(
  state => state.cart,
  state => state.payments,
  state => state.products,
  state => state.delivery,

  (cart, payments, products, { empty, variants }) => {
    let selectDeliveryType

    if (!empty && variants.length) {
      selectDeliveryType = variants.find(deliveryType => deliveryType.id === cart.deliveryType)
    }

    return {
      payments, selectDeliveryType,
      currentPayment: cart.payment,
      deliveryTypes: variants,
      delivery: cart.delivery,
      client: cart.client,
      errors: cart.errors,
      products: cart.addedIds.map(id => {
        return {
          id, count: cart.quantityById[id],
          data: products[id]
        }
      })
    }
  }
)

export const deliveryTypeSelector = createSelector(
  state => state.cart,
  state => state.delivery,

  (cart, delivery) => {
    const request = {
      products: cart.quantityById,
      country: cart.delivery.country,
      city: cart.delivery.city
    }

    return {
      request, pickpoint: cart.delivery.pickpoint, delivery,
      currentDeliveryType: cart.deliveryType
    }
  }
)
