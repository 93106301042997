
export function injectScript (src) {
	return new Promise((resolve, reject) => {
		const s = document.createElement('script')
		s.type = 'text/javascript'
		s.onload = resolve
		s.onerror = reject
		s.src = src

		document.head.appendChild(s)
	})
}