import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { HIDE_NOTIFICATION } from '../redux/reducers/notification'
import { Button } from './shared'
import Modal, { ModalTitle, ModalContent, ModalFooter } from './Modal'

export default () => {
	const { notification, count } = useSelector(({ notification, cart }) => ({ notification, count: cart.addedIds.length }))
	const dispatcher = useDispatch()
	const onDismiss = useCallback(() => {
		dispatcher({
			type: HIDE_NOTIFICATION
		})
	}, [dispatcher])

	if (!notification.show) {
		return null
	}

	return (
		<Modal onDismiss={onDismiss}>
			<ModalTitle>Товар добавлен в корзину</ModalTitle>
			<ModalContent>
				В корзине {count} {count === 1 ? 'товар' : (count < 5 ? 'товара' : 'товаров')}
			</ModalContent>
			<ModalFooter>
				<Button as={Link} to='/order/'>Оформить заказ</Button>
			</ModalFooter>
		</Modal>
	)
}