export const SET_CATALOG = 'SET_CATALOG'

export default (state = {}, action) => {
  switch (action.type) {
    case SET_CATALOG:
      return {
        ...state,
        texts: action.texts,
        categories: action.categories,
      }

    default:
      return state
  }
}