import React, { useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import MaskedInput from 'react-text-mask'

import Modal, { ModalSubTitle, ModalTitle } from './Modal'
import { Button, Col, Input, Row } from './shared'
import Loading from './Loading'
import { processQuickOrder } from '../redux/actions'

const QuickOrder = () => {
	const [phone, setPhone] = useState('')
	const quickOrder = useSelector(state => state.quickOrder)
	const dispatch = useDispatch()

	const onChange = useCallback(e => setPhone(e.target.value), [setPhone])

	const onDismiss = useCallback(() => {
		setPhone('')
		dispatch({
			type: 'CLOSE_QUICK_ORDER'
		})
	}, [dispatch])

	const onSubmit = useCallback((e: Event) => {
		e.preventDefault()

		if (!phone) {
			return false
		}

		dispatch(processQuickOrder(quickOrder.product.meta.id, phone))
	}, [dispatch, phone, quickOrder])

	if (!quickOrder.show) {
		return null
	}

	return (
		<>
			<Modal onDismiss={onDismiss}>
				{quickOrder.thanks ? (
					<>
						<ModalTitle>Быстрый заказ</ModalTitle>
						Спасибо!
						<br/>
						<br/>
						<Button onClick={onDismiss} center>Закрыть</Button>
					</>
				) : (
					<>
						<ModalTitle>Быстрый заказ</ModalTitle>
						<ModalSubTitle>Оставьте ваш телефон и мы сами оформим заказ</ModalSubTitle>
						<form action="" onSubmit={onSubmit}>
							<Row style={{ marginTop: '1rem', marginBottom: '1rem' }}>
								<Col>
									<picture>
										<source srcSet={`https://samovary.ru${quickOrder.product.meta.picture_m1}`.replace('.jpg', '.webp')} type="image/webp"/>
										<img height={200} alt={quickOrder.product.meta.name} src={`https://samovary.ru${quickOrder.product.meta.picture_m1}`}/>
									</picture>
								</Col>
								<Col alignSelf='center' flexGrow style={{ paddingLeft: '1rem' }}>
									{quickOrder.product.meta.name}
								</Col>
							</Row>

							<Row>
								<Col>
									<Input.Label>Телефон</Input.Label>
								</Col>
								<Col flexGrow>
									<Input as={MaskedInput} mask={['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]} placeholder='+7 (9__) ___-__-__ *' value={phone} onChange={onChange}/>
									{/*<Input.Help>Введите 10 цифр. Например 926 110 81 00</Input.Help>*/}
								</Col>
							</Row>
							<br/>
							<Button as='button' type='submit' center>Заказать</Button>
						</form>
					</>
				)}

				{quickOrder.processing && <Loading fade/>}
			</Modal>
		</>
	)
}

export default QuickOrder