import AccordionData from '../data/home-page'
import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'

// region Styled

const Accordion = styled.div`
	border-radius: 4px;
	background-color: #f8f6f9;
	margin-bottom: 1rem;
	overflow: hidden;
`

Accordion.Content = styled.div`
	background: rgba(0, 0, 0, .02);
	padding: 1rem;
	font-size: .95rem;
	
	p + p {
		margin-top: .5rem;
	}
`

Accordion.Collapse = styled.div`
	margin: 0 -1rem;
	transition: all .2s ease;
`

Accordion.Title = styled.span`
	font-size: .95rem;
`

Accordion.Row = styled.div`
	padding: .8rem 1rem;
	position: relative;
	cursor: pointer;

	${props => props.open && css`padding-bottom: 0`}
	
	&:after {
		position: absolute;
		top: .8rem;
		right: .8rem;
		content: '${props => props.open ? '–' : '+'}';
		line-height: 1.5rem;	
	}
	
	img {
		margin-right: .5rem;
	}
	
	& + & {
		border-top: 1px solid #000;
	}
	
	${Accordion.Collapse} {
		max-height: 0;
		overflow: hidden;
		
		${props => props.open && css`
			max-height: 500px; 
			margin-top: .5rem
		`}
	}
`

// endregion

const AccordionPromo = () => {
	const [active, setActive] = useState(-1)

	return useMemo(() => (
		<Accordion>
			{AccordionData.map((accordion, index) => {
				return (
					<Accordion.Row key={index} open={active === index} onClick={() => setActive(active === index ? -1 : index)}>
						<img alt={accordion.title} src={accordion.img} width={28} height={16}/>
						<Accordion.Title>{accordion.title}</Accordion.Title>
						<Accordion.Collapse>
							<Accordion.Content>
								{accordion.content.map((p, index) => {
									return (
										<p key={index} dangerouslySetInnerHTML={{ __html: p.join('<br>') }}/>
									)
								})}
							</Accordion.Content>
						</Accordion.Collapse>
					</Accordion.Row>
				)
			})}
		</Accordion>
	), [active])
}

export default AccordionPromo