import React, { memo, useCallback } from 'react'
import styled from 'styled-components'
import LazyLoad from 'react-lazy-load'
import type { ProductInterface } from '../types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ADD_TO_CART } from '../redux/reducers/cart'
import { SHOW_NOTIFICATION } from '../redux/reducers/notification'

export const Product = styled(Link)`
	display: flex;
	flex-direction: column;
	font-family: Arial,sans-serif;
	padding: 1rem;
	border: 1px solid rgba(0,0,0,.1);
	border-radius: 10px;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	color: #000;
`

Product.ImageWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: .5rem;
`

Product.Image = (props) => (
	<LazyLoad offset={500} height={200}>
		<picture>
			<source srcSet={props.src.replace('.jpg', '.webp')} type="image/webp"/>
			<img alt='' {...props}/>
		</picture>
	</LazyLoad>
)

Product.Title = styled.div`
	max-width: 90%;
`

Product.Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: .8rem;
`

Product.Price = styled.div`
	font-size: 1.6rem;
	font-weight: bold;
	
	&:after {
	  font-family: PT Sans, sans; 
		content: ' ₽'
	}
`

Product.DiscountPrice = styled.div`
	display: flex;
	align-items: center;
	margin-top: .7rem;
`

Product.PriceDiff = styled(Product.Price)`
	margin-left: 1rem;
	font-size: .8rem;
	font-weight: normal;
	background: red;
	border-radius: 1rem;
	color: #fff;
	padding: 0 .5rem;
	line-height: 1.5;
	
	&:before {
		content: '-'
	}
`

Product.PrevPrice = styled(Product.Price)`
	font-size: 1.2rem;
	font-weight: normal;
	text-decoration: line-through;
`

Product.Discount = styled.div`
	position: absolute;
	top: 1rem;
	left: 1rem;
	background: rgba(255,217,0,.73);
	border-radius: 100%;
	width: 3rem;
	height: 3rem;
	line-height: 3rem;
	text-align: center;
	font-size: .9rem;
`

Product.AddToCart = styled.div`
	color: red;
	text-decoration: underline;
	
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-right: .5rem;
    width: 19px;
    height: 23px;
    background: url(https://samovary.ru/shopping-cart/media/css_sprites.png) -10px -345px;
	}
`

export default memo(({ good }: { good: ProductInterface }) => {
	const dispatcher = useDispatch()
	const toggleCart = useCallback((e) => {
		e.preventDefault()

		dispatcher({
			type: ADD_TO_CART,
			productId: good.id
		})

		dispatcher({
			type: SHOW_NOTIFICATION,
		})
	}, [dispatcher, good])

	return (
		<Product to={`/good/${good.id}/`}>
			{good.has_discount && (
				<Product.Discount>
					-{Math.ceil(100 - (good.price / good.price_old) * 100)}%
				</Product.Discount>
			)}

			<Product.ImageWrapper>
				<Product.Image alt={good.name} src={`https://samovary.ru${good.picture_m1}`}/>
			</Product.ImageWrapper>

			<Product.Title>{good.name}</Product.Title>

			{good.has_discount && (
				<Product.DiscountPrice>
					<Product.PrevPrice>{good.price_old.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}</Product.PrevPrice>
					<Product.PriceDiff>{good.price_old - good.price}</Product.PriceDiff>
				</Product.DiscountPrice>
			)}

			<Product.Footer>
				<Product.Price>
					{good.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
				</Product.Price>
				<Product.AddToCart onClick={toggleCart}>
					В корзину
				</Product.AddToCart>
			</Product.Footer>
		</Product>
	)
})