import { Action } from 'redux'
import { LOCATION_CHANGE } from './location'

export const SHOW_NOTIFICATION = 'SHOW_NOTIFICATION'
export const HIDE_NOTIFICATION = 'HIDE_NOTIFICATION'

const initialState = {
	show: false,
}

export default (state = initialState, action: Action) => {
	switch (action.type) {
		case SHOW_NOTIFICATION: {
			return {
				...state,
				show: true
			}
		}

		case LOCATION_CHANGE:
		case HIDE_NOTIFICATION: {
			return {
				...state,
				show: false
			}
		}

		default:
			return state
	}
}