import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import LazyLoad from 'react-lazy-load'

const Footer = styled.div`
	margin: 1rem 0 0;
	padding: 1rem 1rem 5rem;
	background: gold;
	columns: 9rem;
	
	> a {
		break-inside: avoid;
		display: block;
		margin: 0 0 1rem;
		color: #000;
	}
	
	div {
		column-span: all;
		margin-top: 1rem;
		
		.LazyLoad img {
			display: block;
			margin-bottom: 1rem;
		} 
	}
`

export default () => {
	return (
		<Footer>
			<Link to="/about/">О компании</Link>
			<Link to="/news/78/">Доставка и оплата</Link>
			<Link to="/news/154/">Гарантия</Link>
			<Link to="/news/149/">Аренда самовара</Link>
			<Link to="/news/94/">Гравировка</Link>
			<Link to="/news/89/">Реставрация</Link>
			{/*<Link to="/news/162/">Выкуп самоваров</Link>*/}
			<Link to="/news/153/">Отзывы</Link>
			<Link to="/news/191/">Сертификаты</Link>
			<Link to="/news/192/">Видео</Link>
			<Link to="/news/167/">Сотрудничество</Link>
			{/*<Link to="/news/157/">Доставка в США и Европу</Link>*/}
			<Link to="/contacts/">Контакты</Link>

			<LazyLoad width={150} height={48}>
				<img width={150} height={48} src="https://samovary.ru/shopping-cart/media/pay-types/visa_mastercard.png" alt="Оплата с помощью Visa или MasterCard"/>
			</LazyLoad>

			<div>
				Москва, Дубининская,70 +7(495)744-1041<br />
				Санкт-Петербург, Гороховая,69 +7(812)313-2450
			</div>

			<div>
				© Самовары.Ру. 2005-{new Date().getFullYear()}. Все права защищены. ООО&nbsp;“Тульские&nbsp;самовары” ОГРН&nbsp;5157746013739<br />
				<a href="mailto:zakaz@samovary.ru">zakaz@samovary.ru</a> &nbsp;&nbsp; 8-800-775-05-91<br />
				<br />
				<br />
			</div>
		</Footer>
	)
}