import React from 'react'
import { CSSTransition } from 'react-transition-group'
import styled, { css, keyframes } from 'styled-components'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`

const LoadingWrapper = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: Arial, sans-serif;
	font-size: 30px;
	z-index: 222222;
	
	${props => props.fade && css`background: rgba(255, 255, 255, .3);`}
	
	&.loading-enter {
		opacity: 0.01;
	}
	
	&.loading-enter.loading-enter-active {
		opacity: 1;
		transition: opacity 500ms ease-in;
	}
	
	&.loading-leave {
		opacity: 1;
	}
	
	&.loading-leave.loading-leave-active {
		opacity: 0.01;
		transition: opacity 300ms ease-in;
	}
	
	&:before {
		content: '';
		width: 4rem;
		height: 4rem;
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -2rem 0 0 -2rem;
		font-size: 10px;
		border-top: 2px solid red;
		border-right: 2px solid rgba(255,255,255,0);
		border-bottom: 2px solid red;
		border-left: 2px solid red;
		border-radius: 50%;
		animation: ${rotate} 1s infinite linear;
	}
`
export default ({ fade = false, onClick }) => (
	<CSSTransition in={true} timeout={300} classNames="loading">
		<LoadingWrapper fade={fade} onClick={onClick} />
	</CSSTransition>
)