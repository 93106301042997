import React, { useEffect, useRef } from 'react'
import serialize from 'form-serialize'
import styled, { css } from 'styled-components'
import { withRouter } from 'react-router'

export const formatPrice = (price) => price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')

export const GenericPrice = styled.div`
	&:after {
		content: ' ₽'
	}
`

export const IconCalendar = styled(({ className }) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={className} viewBox="75 1026 14 14">
    <g fill="%23333332" fillRule="evenodd">
      <path
        d="M87.834 1027.313h-1.897v-.876a.437.437 0 1 0-.874 0v.876h-2.626v-.876a.437.437 0 1 0-.874 0v.876h-2.626v-.876a.437.437 0 1 0-.874 0v.876h-1.896c-.645 0-1.167.521-1.167 1.166v10.354c0 .645.522 1.167 1.167 1.167h11.667c.644 0 1.166-.522 1.166-1.167v-10.354c0-.645-.522-1.167-1.166-1.167zm.291 11.52c0 .161-.13.292-.291.292H76.167a.292.292 0 0 1-.292-.292v-10.354c0-.16.13-.292.292-.292h1.895v.875a.437.437 0 1 0 .876 0v-.875h2.624v.875a.437.437 0 1 0 .876 0v-.875h2.624v.875a.437.437 0 1 0 .876 0v-.875h1.896c.16 0 .291.131.291.292v10.354z"/>
      <path d="M78.063 1031.25h1.75v1.313h-1.75zm0 2.188h1.75v1.312h-1.75zm0 2.187h1.75v1.313h-1.75zm3.062 0h1.75v1.313h-1.75zm0-2.188h1.75v1.313h-1.75zm0-2.187h1.75v1.313h-1.75zm3.063 4.375h1.75v1.313h-1.75zm0-2.188h1.75v1.313h-1.75zm0-2.187h1.75v1.313h-1.75z"/>
    </g>
  </svg>
))`
	display: inline-block;
	vertical-align: text-bottom;
	width: 1rem;
	height: 1rem;
`

export const Icon = styled.i`
	display: inline-block;
	line-height: 1;
	vertical-align: baseline;
	position: relative;

	&:before {
		content: '';
		display: block;
		width: 2rem;
		height: 2rem;
		backface-visibility: hidden;
		background-image: url(https://samovary.ru/eskimobi/css/img/sprite-icons.png);
		background-position: 0 0;
		background-repeat: no-repeat;
		background-size: 1000% 400%;
		
		${props => props.menu && css`background-position: 0 0`}
		${props => props.basket && css`background-position: 33.33333333% 0;`}
	}
`

export const Section = styled.div`
  border-top: 1px solid #f3f3f3;
  margin: 1rem -1rem;
  padding: 1rem 1rem 0;
  
  h2 + h3 {
    color: #bbb;
    line-height: 1.2;
    font-size: 1rem;
    font-weight: normal;
    margin-top: -.5rem;
    margin-bottom: 1rem;
  }
`

export const Row = styled.div`
	display: flex;
	${props => props.justifyItems && css`justify-items: center;`}
	${props => props.alignItems && css`align-items: center;`}
`

export const Col = styled.div`
	${props => props.flexShrink && css`flex-shrink: 1;`}
	${props => props.flexGrow && css`flex-grow: 1;`}
	${props => props.alignSelf && css`align-self: ${props.alignSelf};`}
`

export const Button = styled.span`
	display: block;
	background: gold;
	color: #000;
	padding: 1rem 1rem;
	border-radius: .2rem;
	cursor: pointer;
	margin: 0;
	
	button& {
	  font-size: 1rem;
	  border: 0;
	  appearance: none;
	  width: 100%;
	  outline: none
	}
	
	${props => props.invert && css`
		border: 1px solid gold;
		background: transparent;
		color: #000;
	`}
	
	${props => props.center && css`text-align: center`}
`

export const Input = styled(({ error, focus, ...props }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (focus) {
    	ref.current.focus();
		}
  }, [ref, focus])

  return <input ref={ref} {...props} />
})`
	border: 1px solid #9c9c9c;
	border-radius: 6px;
	width: 100%;
	padding: .5rem .71428571rem;
	font-size: 1rem;
	line-height: 1.42857142;
	
	${props => props.error && css`
	  border-color: red;
	`}
	
	::placeholder,
  ::-webkit-input-placeholder {
		color: #aaa
	}
`

Input.Help = styled.div`
  color: #9c9c9c;
  font-size: .9rem;
  line-height: 1rem;
  padding: .2rem calc(.71428571rem + 1px) .7rem;
`

Input.Spacer = styled.div`
	padding: .7rem 0 0;
`

Input.Label = styled.div`
	font-size: 1rem;
	line-height: 1.42857142;
	padding: .5rem .5rem .5rem 0;
`

export const SearchForm = withRouter(class extends React.Component {
  ref = React.createRef()

  handleSubmit = (event) => {
    event.preventDefault()

    this.props.history.push('/article/34/?' + serialize(this.ref.current))
  }

  render () {
    const { children, className } = this.props

    return (
      <form className={className} ref={this.ref} onSubmit={this.handleSubmit}>
        {children}
      </form>
    )
  }

})

export const ScrollTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return null
}