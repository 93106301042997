import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const Breadcrumbs = styled(({className, breadcrumbs}) => (
	<div className={className}>
		<Link to={'/'}>Главная</Link>
		{breadcrumbs.map((breadcrumb, index) => (
			breadcrumb.url ? <Link key={index} to={breadcrumb.url}>{breadcrumb.title}</Link>
				: <span key={index}>{breadcrumb.title}</span>
		))}
	</div>
))`
	margin-bottom: 1rem;

	a + a,
	a + span {
		&:before {
			content: ' → ';
			pointer-events: none;
			color: #000;
		}
	}

`

export default Breadcrumbs