export const SET_CATEGORY_BY_ID = 'SET_CATEGORY_BY_ID'
export const SET_GOODS_BY_QUERY = 'SET_GOODS_BY_QUERY'

const defaultState = {
	categories: {},
	goods: {}
}

const categories = (state = defaultState, action) => {
	switch (action.type) {
		case SET_GOODS_BY_QUERY:
			return {
				...state,
				goods: {
					...state.goods,
					[action.query]: action.goods
				}
			}

		case SET_CATEGORY_BY_ID:
			return {
				...state,
				categories: {
					...state.categories,
					[action.categoryId]: {
						loading: false,
						...action.data
					}
				}
			}

		default:
			return state
	}
}

export default categories