import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import Header from '../components/Header'
import { showSidebar } from '../redux/actions'

const mapStateToProps = state => {
	return {
		count: state.cart.addedIds.reduce((prev, item) => prev + state.cart.quantityById[item], 0)
	}
}

const mapDispatchToProps = dispatch => (
	bindActionCreators({ showSidebar }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(Header)