export default [
  'Россия',
  'Украина',
  'Казахстан',
  'Беларусь',
  'Армения',
  'Грузия',
  'Азербайджан',
  'Соединенные Штаты Америки (США)',
  'Канада',
  'Великобритания',
  'Испания',
  'Италия',
  'Греция',
  'Германия',
  'Латвия',
  'Литва',
  'Австралия',
  'Австрия',
  'Албания',
  'Алжир',
  'Андорра',
  'Аргентина',
  'Бельгия',
  'Болгария',
  'Боливия',
  'Босния и Герцеговина',
  'Бразилия',
  'Ватикан',
  'Венгрия',
  'Венесуэла',
  'Вьетнам',
  'Гибралтар',
  'Гондурас',
  'Гонконг',
  'Гренада',
  'Гренландия',
  'Дания',
  'Доминиканская Республика',
  'Египет',
  'Израиль',
  'Индия',
  'Индонезия',
  'Иордания',
  'Ирак',
  'Иран',
  'Ирландия',
  'Исландия',
  'Камбоджа',
  'Катар',
  'Кипр',
  'Киргизия (Кыргызстан)',
  'Китай',
  'Колумбия',
  'Корейская Народно-Демократическая Республика',
  'Корея Республика',
  'Куба',
  'Кувейт',
  'Ливан',
  'Ливия',
  'Лихтенштейн',
  'Люксембург',
  'Македония',
  'Малави',
  'Малайзия',
  'Мальдивы',
  'Мальта',
  'Марокко',
  'Мексика',
  'Молдова',
  'Монако',
  'Монголия',
  'Нидерланды',
  'Новая Зеландия',
  'Норвегия',
  'Объединенные Арабские Эмираты',
  'Пакистан',
  'Палестина',
  'Панама',
  'Парагвай',
  'Перу',
  'Польша',
  'Португалия',
  'Румыния',
  'Саудовская Аравия',
  'Сербия',
  'Сингапур',
  'Сирия',
  'Словакия',
  'Словения',
  'Таджикистан',
  'Таиланд',
  'Тунис',
  'Туркменистан',
  'Турция',
  'Узбекистан',
  'Уругвай',
  'Филиппины',
  'Финляндия',
  'Франция',
  'Хорватия',
  'Черногория',
  'Чешская Республика',
  'Чили',
  'Швейцария',
  'Швеция',
  'Шри-Ланка',
  'Эквадор',
  'Эстония',
  'Эфиопия',
  'Ямайка',
  'Япония',
]