import { Action } from 'redux'
import { LOCATION_CHANGE } from './location'

const initialState = {
	show: false,
	processing: false,
	product: null
}

export default (state = initialState, action: Action | { product: {} }) => {
	switch (action.type) {
		case 'OPEN_QUICK_ORDER': {
			return {
				...state,
				show: true,
				product: action.product
			}
		}

		case 'SUBMIT_QUICK_ORDER': {
			return {
				...state,
				processing: true
			}
		}

		case 'SUCCESS_QUICK_ORDER': {
			if (!state.processing) {
				return state
			}

			return {
				...state,
				processing: false,
				thanks: true
			}
		}

		case LOCATION_CHANGE:
		case 'CLOSE_QUICK_ORDER': {
			return {
				...state,
				thanks: false,
				processing: false,
				show: false,
				product: null
			}
		}

		default:
			return state
	}
}