import { combineReducers } from 'redux'
import sidebar from './sidebar'
import categories from './categories'
import cart from './cart'
import products from './products'
import delivery from './delivery'
import payments from './payments'
import pages from './pages'
import notification from './notification'
import order from './order'
import quickOrder from './quick-order'
import search from './search'
import catalog from './catalog'

export default combineReducers({
	cart,
	sidebar,
	categories,
	pages,
	products,
	delivery,
	payments,
	order,
	notification,
	search,
	quickOrder,
	catalog,
})