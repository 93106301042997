import styled from 'styled-components'

const Page = styled.div`
	h1 {
		font-size: 1.3rem;
		margin: 0 0 1rem;
	}
	
	h2 {
		font-size: 1.1rem;
		margin: 0 0 1rem;
	}
	
	h3 {
		font-size: 1.1rem;
		margin: 0 0 1rem;
	}
	
	p, iframe {
	  display: block;
	  margin: 1rem 0;  
	}
	
	img, iframe {
	  max-width: 100% !important;
	}
  
  .fix-content {
  	h2 {
  		margin-top: 1rem;
  	}
  	
  	h2 + br {
  		display: none;
  	}
  }
  
  #pano {
  	width: 100% !important;
  }
  
  form[action^="/feedback.php"] {
  	margin-bottom: 1rem;
  	
  	table {
  	  width: 100%;
  	  table-layout: fixed;
  	  
  	  td {
  	    padding: 0;
  	  }
  	}

  	input[type="file"], input[type="text"] {
  	  max-width: 100%;
  	}
  
  	input[type="submit"] {
  		display: block;
			background: gold;
			color: #000;
			padding: 1rem 1rem;
			border-radius: .2rem;
			cursor: pointer;
			margin: 0;
			border: 0;
			width: 100%;
			height: auto !important;
			line-height: 1 !important;
  	}
  
  	input[type="text"], textarea {
			border: 1px solid #9c9c9c;
			border-radius: 6px;
			width: 100%;
			padding: .5rem .71428571rem;
			font-size: 1rem;
			line-height: 1.42857142;
			
			&::placeholder,
			&::-webkit-input-placeholder {
				color: #aaa
			}
  	}
  }
`

export default Page