import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Sidebar from '../components/Sidebar'
import { hideSidebar } from '../redux/actions'

const mapStateToProps = ({ sidebar: { categories, visible } }) => ({
	visible, categories
})

const mapDispatchToProps = dispatch => (
	bindActionCreators({ hideSidebar }, dispatch)
)
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)